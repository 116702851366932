import React from 'react';
import Header from '../components/header';
import DetailBody from '../components/detail-body';
import Footer from '../components/footer';
import ActionBanner from '../components/action-banner';

const Detail = () => {
  return (
    <>
      <Header />
      <DetailBody />
      <ActionBanner />
      <Footer />
    </>
  );
};

export default Detail;
