import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { LS_CURRENT_LANGUAGE } from '../constants';

export const StateContext = React.createContext();

const StateProvider = (props) => {
  const { children } = props;

  const [categories, setCategories] = useState({});
  const [faqs, setFaqs] = useState([]);
  const [topSearches, setTopSearches] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [currentLang, setCurrentLang] = useState(localStorage.getItem(LS_CURRENT_LANGUAGE));

  return (
    <StateContext.Provider
      value={{
        categories,
        setCategories,
        faqs,
        setFaqs,
        topSearches,
        setTopSearches,
        searchTerm,
        setSearchTerm,
        isMobileMenuOpen,
        setMobileMenuOpen,
        documents,
        setDocuments,
        currentLang, // current language
        setCurrentLang, // language switch function
      }}
    >
      {Children.only(children)}
    </StateContext.Provider>
  );
};

// export default connect(state => state.app.user.loggedIn, () => {})(ServiceProvider);
export default withRouter(StateProvider);

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
