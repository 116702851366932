import _HeadingOne from './heading-one';
import _HeadingTwo from './heading-two';
import _HeadingThree from './heading-three';
import _HeadingFour from './heading-four';
import _BodyText from './body-text';
import _BiggerText from './bigger-text';
import _SmallText from './small-text';

export const HeadingOne = _HeadingOne;
export const HeadingTwo = _HeadingTwo;
export const HeadingThree = _HeadingThree;
export const HeadingFour = _HeadingFour;
export const BodyText = _BodyText;
export const BiggerText = _BiggerText;
export const SmallText = _SmallText;
