import styled from 'styled-components';
import { COLOR_GREY_1 } from '../../assets/styles/colors';

export default styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${COLOR_GREY_1};
  height: auto;
  padding: 30px 15px;
  overflow: hidden;
  text-align: center;
  flex-direction: column;
  .row {
    display: flex;
    justify-content: center;

    .col {
      button {
        display: block;
        margin: 0 auto;
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
`;
