import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { ResultsSC, ResultHeadlineSC, ResultBoxSC, ResultCountSC } from './results-body.styled';
import Container from '../container';
import { ReactComponent as LoadingIcon } from '../../assets/images/svg/spinner.svg';

import Api from '../../utils/api';
import { StateContext } from '../../context/state-provider';

const ResultsBody = (props) => {
  const { currentLang } = useContext(StateContext);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState([]);
  const { t } = useTranslation();
  const {
    match: {
      params: { term: searchTerm },
    },
  } = props;

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const searchResponseResult = await Api.search(currentLang, searchTerm);
      setContent(searchResponseResult.data.faqs);
    };
    fetchData();
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [searchTerm, currentLang]);

  const handleFaqClick = (itemId) => {
    props.history.push(`/detail/${itemId}`);
  };

  return (
    <ResultsSC>
      <Container>
        {isLoading ? (
          <div className="loading-wrap">
            <LoadingIcon />
          </div>
        ) : (
          <>
            <ResultCountSC>
              {content.length} {t('support.global.results_found')} “{searchTerm}”
            </ResultCountSC>
            <ResultBoxSC>
              {content.map((faq) => (
                <Fragment key={faq.id}>
                  {' '}
                  <ResultHeadlineSC onClick={() => handleFaqClick(faq.id)}>
                    {faq.question}
                  </ResultHeadlineSC>
                  {
                    // eslint-disable-next-line react/no-danger
                    <div className="faq-answer" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  }
                </Fragment>
              ))}
            </ResultBoxSC>
          </>
        )}
      </Container>
    </ResultsSC>
  );
};

ResultsBody.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      term: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ResultsBody);
