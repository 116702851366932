/* ---------- GLOBAL COLORS ---------- */

export const COLOR_BLACK = '#000000';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_YELLOW = '#F1C410';
export const COLOR_YELLOW_1 = '#FFD966';
export const COLOR_BLUE = '#013082';
export const COLOR_GREY_1 = '#282828';
export const COLOR_GREY_2 = '#F3F4F5';
export const COLOR_GREY_3 = '#E9E9E9';
export const COLOR_GREY_4 = '#989BA0';
