import styled from 'styled-components';
import { COLOR_WHITE, COLOR_YELLOW, COLOR_BLACK } from '../../assets/styles/colors';

export const LangContainerSC = styled.div`
  display: inline-block;
  margin-left: 33px;
  margin-top: 3px;
`;

export const LangSwitcherSC = styled.div`
  position: relative;
  ul {
    position: absolute;
    left: 0px;
    margin: 0;
    padding: 10px;
    z-index: 99;
    list-style: none;
    background: ${COLOR_WHITE};
    &.d-block {
      display: block;
    }
    &.d-none {
      display: none;
    }

    li {
      button {
        text-transform: uppercase;
        margin: 0;
        font-size: 14px;
        background: none;
        border: none;
        color: ${COLOR_BLACK};
        &:hover {
          color: ${COLOR_YELLOW};
          cursor: pointer;
        }
      }
    }
  }
  .lang-main {
    text-transform: uppercase;
    font-size: 14px;
    color: ${COLOR_WHITE};
    background: none;
    border: none;
    cursor: pointer;
    padding-right: 10px;
    &.active {
      &:after {
        transform: rotate(90deg);
      }
    }
    &:after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 4px 5px 4px 0;
      border-color: transparent #ffffff transparent transparent;
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      margin-left: 9px;
    }
  }
`;
