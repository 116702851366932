import React from 'react';
import Header from '../components/header';
import Search from '../components/search';
import FaqBody from '../components/faq-body';
import Footer from '../components/footer';
import ActionBanner from '../components/action-banner';

const Faq = () => {
  return (
    <>
      <Header />
      <Search />
      <FaqBody />
      <ActionBanner />
      <Footer />
    </>
  );
};

export default Faq;
