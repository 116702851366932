import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionBannerSC from './action-banner.styled';
import Container from '../container';
import { HeadingFour, BodyText } from '../typography';

const ActionBanner = () => {
  const { t } = useTranslation();

  return (
    <ActionBannerSC>
      <Container>
        <div className="row">
          <div className="banner-wrap">
            <HeadingFour>{t('support.action_banner.copy')}</HeadingFour>
            <BodyText>
              {t('support.action_banner.btn_text')}{' '}
              <a href={`mailto:${t('support.action_banner.email')}`}>
                {t('support.action_banner.email')}
              </a>
            </BodyText>
          </div>
        </div>
      </Container>
    </ActionBannerSC>
  );
};

export default ActionBanner;
