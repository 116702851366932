import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { detect } from 'detect-browser';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LS_CURRENT_LANGUAGE } from './constants';

const browser = detect();

const version = browser.version;
const dotPosition = version.indexOf('.');
const numberedVersion = parseInt(version.slice(0, dotPosition));

if (browser && browser.name === 'ie' && numberedVersion < 9) {
  const appElem = document.getElementById('app');
  appElem.innerHTML =
    '<p>We are sorry but your browser is outdated. Please ' +
    '<a style="color: blue; text-decoration: underline" href="https://browsehappy.com" target="_self">' +
    'update your browser</a> to current version.</p>';
} else {
  if (localStorage.getItem(LS_CURRENT_LANGUAGE) === null) {
    localStorage.setItem(LS_CURRENT_LANGUAGE, process.env.REACT_APP_DEFAULT_LANG);
  }

  ReactDOM.render(<App />, document.getElementById('app'));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
