import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import BSReboot from './assets/styles/bootstrap-reboot';
import GlobalStyles from './assets/styles/global-styles';
import AppHandler from './components/app-handler';
import StateProvider from './context/state-provider';

const history = createBrowserHistory();
history.listen((location, action) => {
  window.scrollTo(0, 0);
});

const BsStyles = createGlobalStyle`${BSReboot}`;
const GlobalStyle = createGlobalStyle`${GlobalStyles}`;

const teamWorkBaseUrl = 'https://cryptondigital.teamwork.com';
const teamWorkToken = process.env.REACT_APP_TEAMWORK_TOKEN;

const App = () => {
  return (
    <Fragment>
      <Helmet>
        <script>
          {`
        !(function(e) {
            var basehref = ${`"${teamWorkBaseUrl}"`},
                token = ${`"${teamWorkToken}"`};

            window.deskcontactwidget = {};
            var r=e.getElementsByTagName("script")[0],c=e.createElement("script");c.type="text/javascript",c.async=!0,c.src=basehref+"/support/v1/contact/main.js?token="+token,r.parentNode.insertBefore(c,r),window.addEventListener("message",function(e){var t=e.data[0],a=e.data[1];switch(t){case"setContactFormHeight":document.getElementById("deskcontactwidgetframe").height=Math.min(a, window.window.innerHeight - 75)}},!1);
        })(document);
        `}
        </script>
      </Helmet>
      <BsStyles />
      <GlobalStyle />
      <Router history={history}>
        <StateProvider>
          <AppHandler />
        </StateProvider>
      </Router>
    </Fragment>
  );
};

export default App;
