import React from 'react';
import MenuSC from './left-banner.styled';

import { ReactComponent as WexoLogo } from '../../assets/images/svg/logo-b.svg';

const LeftBanner = () => {
  return (
    <MenuSC>
      <div className="logo-part">
        <WexoLogo />
      </div>
    </MenuSC>
  );
};

export default LeftBanner;
