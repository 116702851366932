import React from 'react';
import PropTypes from 'prop-types';

import ContainerSC from './container.styled';

const Container = ({ children, className }) => {
  return <ContainerSC className={className || null}>{children}</ContainerSC>;
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  className: '',
};

export default Container;
