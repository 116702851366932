import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import HeaderWrapperSC from './header.styled';
import Container from '../container';
import { ReactComponent as LogoIcon } from '../../assets/images/svg/logo-b.svg';

import Button from '../ui/button';
import { StateContext } from '../../context/state-provider';
import MobileMenu from '../mobile-menu/mobile-menu';
import LangSwitch from '../lang-switch/lang-switch';

const Header = () => {
  const { t } = useTranslation();
  const { isMobileMenuOpen, setMobileMenuOpen } = useContext(StateContext);

  const redirectToHomepage = () => {
    window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`;
  };

  const redirectToSignUp = () => {
    window.location.href = `${process.env.REACT_APP_AUTH_URI}/registration`;
  };

  const redirectToLogIn = () => {
    window.location.href = `${process.env.REACT_APP_AUTH_URI}/signin`;
  };

  return (
    <>
      {isMobileMenuOpen ? <MobileMenu /> : null}

      <HeaderWrapperSC className={isMobileMenuOpen ? 'mobile-opened' : ''}>
        <Container>
          <div className="row">
            <div className="col-logo">
              <button type="button" onClick={() => redirectToHomepage()}>
                <LogoIcon />
              </button>
              <LangSwitch />
            </div>

            <div className="col-mobile-menu">
              <button type="button" onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                {isMobileMenuOpen ? 'Skryť' : 'Menu'}
              </button>
            </div>
            <div className="col-buttons">
              <Button className="secondary big" onClick={() => redirectToLogIn()}>
                {t('support.header.log_in')}
              </Button>
              <Button className="primary big" onClick={() => redirectToSignUp()}>
                {t('support.header.sign_up')}
              </Button>
            </div>
          </div>
        </Container>
      </HeaderWrapperSC>
    </>
  );
};

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Header);
