import React from 'react';
import { useTranslation } from 'react-i18next';
import MobileMenuWrapperSC from './mobile-menu.styled';
import Container from '../container';
import Button from '../ui/button';

const MobileMenu = () => {
  const { t } = useTranslation();

  const redirectToSignUp = () => {
    window.location.href = `${process.env.REACT_APP_AUTH_URI}/registration`;
  };

  const redirectToLogIn = () => {
    window.location.href = `${process.env.REACT_APP_AUTH_URI}/signin`;
  };

  return (
    <MobileMenuWrapperSC>
      <Container>
        <div className="row">
          <div className="col">
            <Button className="secondary big" onClick={() => redirectToLogIn()}>
              {t('support.header.log_in')}
            </Button>
            <Button className="primary big" onClick={() => redirectToSignUp()}>
              {t('support.header.sign_up')}
            </Button>
          </div>
        </div>
      </Container>
    </MobileMenuWrapperSC>
  );
};

export default MobileMenu;
