import styled from 'styled-components';
import { COLOR_BLACK, COLOR_GREY_3 } from '../../assets/styles/colors';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export const ResultsSC = styled.div`
  min-height: calc(100vh - 832px);
  padding: 0;
  display: block;
  .all-results {
    background: red;
  }
  .loading-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 200px;
    justify-content: center;
    svg {
      height: 15px;
    }
  }
`;

export const ResultBoxSC = styled.div`
  display: block;

  .faq-answer {
    color: ${COLOR_BLACK};
    font-size: 1em;
    line-height: 1.5em;
    max-width: 834px;
    padding: 10px 0 30px 0;
    width: 100%;
    max-width: 830px;
    text-decoration: none;
    border-bottom: 1px solid ${COLOR_GREY_3};
    p {
      margin-bottom: 0px;
    }
    @media screen and (max-width: ${BP_MOBILE_MAX}px) {
      padding: 10px 0 30px 0;
      margin-top: 0;
    }
  }
`;

export const ResultHeadlineSC = styled.a`
  color: ${COLOR_BLACK};
  font-weight: 500;
  font-size: 21px;
  padding-top: 30px;
  display: block;
  cursor: pointer;
  text-decoration: none;
  border-bottom: none !important;
  &:hover {
    text-decoration: underline !important;
  }
  @media screen and (max-width: ${BP_MOBILE_MAX}px) {
    padding-top: 30px;
  }
`;

export const ResultTextSC = styled.p`
  color: ${COLOR_BLACK};
  font-size: 16px;
  line-height: 1.5em;
  max-width: 834px;
  padding: 10px 0 0 0;
  width: 100%;
  text-decoration: none;
  &.result-count {
    color: ${COLOR_BLACK};
    padding-top: 80px;
  }
  @media screen and (max-width: ${BP_MOBILE_MAX}px) {
    padding: 10px 0 0 0;
    margin-top: 0px;
  }
`;

export const ResultCountSC = styled.p`
  font-size: 25px;
  font-weight: 500;
  color: ${COLOR_BLACK};
  padding: 80px 0 0 0;
  width: 100%;
  @media screen and (max-width: ${BP_MOBILE_MAX}px) {
    padding: 40px 0 0 0;
  }
`;
