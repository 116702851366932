import React, { useContext, useRef, useEffect, useState } from 'react';
import { LS_CURRENT_LANGUAGE } from '../../constants';
import { StateContext } from '../../context/state-provider';
import { LangContainerSC, LangSwitcherSC } from './lang-switch.styled';

const LangSwitch = () => {
  const myRef = useRef();
  const [langMenu, setLangMenu] = useState(false);
  const { currentLang, setCurrentLang } = useContext(StateContext);
  const languagesToSelect = [
    'en',
    'sk',
    'cs',
    'de',
    'es',
    'fr',
    'hr',
    'hu',
    'it',
    'pl',
    'pt',
    'ro',
    'ru',
    'sr',
  ];

  const openMenu = () => {
    setLangMenu(!langMenu);
  };

  const setAppLanguage = (langSelected) => {
    localStorage.setItem(LS_CURRENT_LANGUAGE, langSelected);
    setCurrentLang(langSelected);
    setLangMenu(false);
  };

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setLangMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <LangContainerSC>
      <LangSwitcherSC ref={myRef}>
        <button
          type="button"
          className={langMenu ? 'lang-main active' : 'lang-main'}
          onClick={openMenu}
        >
          {currentLang}
        </button>
        <ul className={langMenu ? 'd-block' : 'd-none'}>
          {languagesToSelect.map((l) => {
            return (
              <li key={l}>
                <button type="button" value={l} onClick={(e) => setAppLanguage(e.target.value)}>
                  {l}
                </button>
              </li>
            );
          })}
        </ul>
      </LangSwitcherSC>
    </LangContainerSC>
  );
};

export default LangSwitch;
