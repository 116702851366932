import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK, COLOR_WHITE, COLOR_GREY_4 } from '../../assets/styles/colors';

export const SmallTextSC = styled.small`
  color: ${COLOR_BLACK};
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
  display: inline-block;
  &.white {
    color: ${COLOR_WHITE};
  }
  &.bolder {
    font-weight: 500;
  }
  &.smaller {
    font-size: 12px;
  }
  &.thinner {
    font-weight: 300;
  }
  &.grey {
    color: ${COLOR_GREY_4};
  }
`;

const SmallText = (props) => {
  const { children, className } = props;
  const customClass = classNames({ [className]: className !== '' });

  return <SmallTextSC className={customClass}>{children}</SmallTextSC>;
};

SmallText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

SmallText.defaultProps = {
  className: '',
};

export default SmallText;
