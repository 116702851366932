import styled from 'styled-components';
import { BP_TABLET_MAX, BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export default styled.div`
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;

  button {
    margin-top: 0px;
  }

  .row {
    display: flex;
    float: right;
    width: calc(100% - 120px);
    min-height: 100vh;
    padding: 50px 0;
    align-items: center;
    justify-content: center;
    .col {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      .info-text {
        padding-top: 10px;
        padding-bottom: 20px;
        max-width: 407px;
      }
      .error-img {
        margin: 40px 0;
      }
    }
  }

  @media (max-width: ${BP_TABLET_MAX}px) {
    .row {
      width: 100%;
      .col {
        button {
          display: block;
        }
      }
    }
  }

  @media (max-width: ${BP_MOBILE_MAX}px) {
    .row {
      padding: 120px 0 50px 0;

      .col {
        .error-title {
          margin-top: 50px;
        }
      }
    }
  }
`;
