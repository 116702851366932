import axios from 'axios';

class Api {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
    this.axios = axios.create({
      timeout: process.env.REACT_APP_AXIOS_TIMEOUT,
    });
    this.lang = process.env.REACT_APP_DEFAULT_LANG;
  }

  /**
   * Get all FAQ's with responses by categories
   */
  getCategories = (lang) => this.axios.get(`${this.baseURL}/rest/${lang}/faq/categories`);

  /**
   * Get all FAQ's
   */
  getFAQs = (lang, categoryId = '') =>
    this.axios.get(`${this.baseURL}/rest/${lang}/faq/questions/${categoryId}`);

  /**
   * Response to specified FAQ
   */
  getFAQResponse = (lang, faqId) =>
    this.axios.get(`${this.baseURL}/rest/${lang}/faq/answer/${faqId}`);

  /**
   * Search
   */
  search = (lang, term) => this.axios.get(`${this.baseURL}/rest/${lang}/faq/search/${term}`);

  /**
   * Get most frequented search terms
   */
  getMostFrequentedTerms = (lang, limit = '3') =>
    this.axios.get(`${this.baseURL}/rest/${lang}/faq/frequently-searched/${limit}`);

  getTranslations = (lang, type) => {
    if (type === 'support') {
      return this.axios.get(`${this.baseURL}/rest/${lang}/translations/${type}`);
    }
    return null;
  };

  getDocuments = (lang) => this.axios.get(`${this.baseURL}/rest/${lang}/documents`);
}

export default new Api();
