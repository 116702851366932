import styled from 'styled-components';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';
import { COLOR_BLACK, COLOR_GREY_4 } from '../../assets/styles/colors';

export default styled.div`
  width: calc(50% - 32px);
  display: flex;
  flex-direction: column;
  padding-top: 75px;

  @media (max-width: ${BP_MOBILE_MAX}px) {
    width: calc(100% - 32px);
    padding-top: 40px;

    &:first-child {
      padding-top: 20px;
    }
  }
`;

export const FaqHeadlineSC = styled.h2`
  color: ${COLOR_BLACK};
  font-weight: 500;
  font-size: 23px;

  @media (max-width: ${BP_MOBILE_MAX}px) {
    font-size: 23px;
  }
`;

export const FaqLinkSC = styled.a`
  cursor: pointer;
  color: ${COLOR_GREY_4} !important;
  font-size: 16px;
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #e3e8ef;
  text-decoration: none;
  &:hover {
    color: ${COLOR_BLACK} !important;
  }
`;
