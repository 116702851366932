export const BP_MOBILE_MAX = 765;
export const BP_MOBILE_MIN = 765.98;

export const BP_TABLET_MAX = 1024;
export const BP_TABLET_MIN = 1024.98;

// export const BP_NETBOOK_MAX = 1279.98;
// export const BP_NETBOOK_MIN = 1280;

export const BP_NOTEBOOK_MAX = 1365.98;
// export const BP_NOTEBOOK_MIN = 1366;

// export const BP_DESKTOP_MAX = 1919.8;
// export const BP_DESKTOP_MIN = 1920;

// export const BP_2K_MAX = 2559.98;
// export const BP_2K_MIN = 2560;
