import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { SearchSC, SearchInputSC, SearchFormSC, TopSearchesSC } from './search.styled';
import Container from '../container';
import { StateContext } from '../../context/state-provider';
import HeadingOne from '../typography/heading-one';
import Button from '../ui/button';
import SmallText from '../typography/small-text';
import { ReactComponent as GlyphIcon } from '../../assets/images/svg/glyph.svg';

const Search = (props) => {
  const { t } = useTranslation();
  const {
    match: {
      params: { term: uriSearchTerm },
    },
  } = props;
  const [searchTerm, setSearchTerm] = useState(uriSearchTerm);
  const { topSearches } = useContext(StateContext);

  const handleSearchClick = () => {
    return searchTerm !== '' && searchTerm !== undefined
      ? props.history.push(`/results/${searchTerm}`)
      : props.history.push('/');
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      return searchTerm !== '' && searchTerm !== undefined
        ? props.history.push(`/results/${searchTerm}`)
        : props.history.push('/');
    }
    return null;
  };

  const handleTopSearchClick = (term) => {
    return props.history.push(`/results/${term}`);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (uriSearchTerm) {
      setSearchTerm(uriSearchTerm);
    }
  }, [uriSearchTerm]);

  return (
    <SearchSC>
      <GlyphIcon className="glyph-icon" />
      <Container>
        <div className="row">
          <HeadingOne className="yellow">{t('support.search.headline')}</HeadingOne>
          <SearchFormSC>
            <SearchInputSC
              type="text"
              placeholder={t('support.search.placeholder')}
              value={searchTerm}
              onChange={handleSearchInputChange}
              onKeyPress={handleEnterPress}
            />
            <Button className="primary big" onClick={() => handleSearchClick()}>
              {t('support.search.btn_text')}
            </Button>
          </SearchFormSC>
          <SmallText className="grey">{t('support.search.top_search')}:</SmallText>
          {topSearches
            ? topSearches.map((topTerm) => {
                return (
                  <TopSearchesSC
                    key={topTerm}
                    type="button"
                    onClick={() => handleTopSearchClick(topTerm)}
                  >
                    {topTerm}
                  </TopSearchesSC>
                );
              })
            : null}
        </div>
      </Container>
    </SearchSC>
  );
};

Search.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      term: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Search);
