import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NoMatchSC from './no-match.styled';
import Container from '../container';
import { BodyText } from '../typography';
import Button from '../ui/button';
import { ReactComponent as Error404 } from '../../assets/images/svg/error404.svg';

const NoMatch = () => {
  const { t } = useTranslation();

  const redirectToHomepage = () => {
    window.open(`${process.env.REACT_APP_HOMEPAGE_URL}`, '_self');
  };
  return (
    <NoMatchSC>
      <Container>
        <div className="row">
          <div className="col">
            {/* <BodyText>{t('support.no_match.error_page')}</BodyText> */}
            <BodyText className="error-title">Error page</BodyText>
            <Error404 className="error-img" />
            {/* <BodyText>{t('support.no_match.what_happen')}</BodyText> */}
            <BodyText>What happen</BodyText>
            <BodyText className="info-text smaller">{t('support.no_match.copy')}</BodyText>
            <Button className="primary not-rounded bigger" onClick={() => redirectToHomepage()}>
              Back to home
            </Button>
          </div>
        </div>
      </Container>
    </NoMatchSC>
  );
};

NoMatch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(NoMatch);
