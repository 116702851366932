import styled from 'styled-components';
import { COLOR_BLUE, COLOR_GREY_4, COLOR_BLACK, COLOR_GREY_1 } from '../../assets/styles/colors';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export const DetailSC = styled.div`
  width: 100%;
  min-height: calc(100vh - 440px);
  .row {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
  }

  @media screen and (max-width: ${BP_MOBILE_MAX}px) {
    min-height: calc(100vh - 360px);
  }
`;

export const DetailLinkWrapperSC = styled.div`
  max-width: 430px;
  flex-basis: calc(1 / 3 * 100%);

  h4 {
    font-weight: 500;
    padding-bottom: 20px;
  }
  .loading-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 200px;
    justify-content: center;
    svg {
      height: 15px;
    }
  }
  button {
    color: ${COLOR_GREY_4};
    background: none;
    border: none;
    padding: 0px;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 20px;
    max-width: 220px;
    display: block;
    text-align: left;
    cursor: pointer;
    &:hover {
      color: ${COLOR_BLACK};
    }
    &.active {
      color: ${COLOR_BLACK};
    }
  }
  @media screen and (max-width: ${BP_MOBILE_MAX}px) {
    margin-right: 0px;
    max-width: 100%;
    margin-bottom: 20px;
    flex-basis: 100%;
  }
`;

export const DetailContentWrapperSC = styled.div`
  flex-basis: calc(2 / 3 * 100%);
  word-break: break-word;

  h1 {
    padding-bottom: 10px;
  }

  /* a {
    color: ${COLOR_BLUE};
  } */

  p {
    font-size: 16px;
    line-height: 1.5em;
    color: ${COLOR_GREY_1};
    max-width: 800px;
  }

  @media screen and (max-width: ${BP_MOBILE_MAX}px) {
    flex-basis: 100%;
  }
`;

export const BreadcrumbWrapperSC = styled.div`
  padding-top: 18px;
  flex-basis: 100%;
  padding-bottom: 50px;
  a {
    font-size: 14px;
    text-decoration: none;
  /*   color: ${COLOR_BLUE}; */
  }
  span {
    font-size: 14px;
    text-decoration: none;
    color: ${COLOR_GREY_4};
    &::before {
      content: '>';
      padding: 0 8px;
    }
  }
  @media screen and (max-width: ${BP_MOBILE_MAX}px) {
    padding-bottom: 20px;
  }
`;
