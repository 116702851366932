import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK, COLOR_WHITE, COLOR_BLUE, COLOR_YELLOW } from '../../assets/styles/colors';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export const HeadingOneSC = styled.h1`
  color: ${COLOR_BLACK};
  font-size: 49px;
  line-height: 1.36;
  font-weight: 500;
  margin-bottom: 0;
  &.white {
    color: ${COLOR_WHITE};
  }
  &.blue {
    color: ${COLOR_BLUE};
  }
  &.bold {
    font-weight: bold;
  }
  &.yellow {
    color: ${COLOR_YELLOW};
  }
  @media (max-width: ${BP_MOBILE_MAX}px) {
    font-size: 37px;
  }
`;

const HeadingOne = (props) => {
  const { children, className } = props;
  const customClass = classNames({ [className]: className !== '' });

  return <HeadingOneSC className={customClass}>{children}</HeadingOneSC>;
};

HeadingOne.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

HeadingOne.defaultProps = {
  className: '',
};

export default HeadingOne;
