import styled from 'styled-components';
import { COLOR_BLUE, COLOR_BLACK } from '../../assets/styles/colors';
import { BP_TABLET_MAX } from '../../assets/styles/breakpoints';

export default styled.nav`
  height: 100%;
  min-height: 100vh;
  width: 120px;
  display: flex;
  z-index: 99;
  background-color: ${COLOR_BLACK};
  flex-direction: column;
  position: absolute;
  left: 0px;

  .logo-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 120px;
    width: 100%;
    background: ${COLOR_BLUE};
    svg {
      width: 94px;
    }
  }

  @media (max-width: ${BP_TABLET_MAX}px) {
    height: 120px;
    width: 120px;
    display: flex;
    bottom: 0px;
    z-index: 999999;
    flex-direction: row;
    position: absolute;
    left: 0px;
    background-color: transparent;
  }
`;
