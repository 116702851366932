import React, { useState, useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ReactComponent as LoadingIcon } from '../assets/images/svg/spinner.svg';

import Api from '../utils/api';
import Faq from '../pages/faq';
import Results from '../pages/results';
import Detail from '../pages/detail';
import NoMatchPage from '../pages/no-match';
import { StateContext } from '../context/state-provider';

export default () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setCategories, setFaqs, setTopSearches, setDocuments, currentLang } = useContext(
    StateContext
  );

  /**
   * Try to get some data, and save it to context, otherwise render error..
   */
  useEffect(() => {
    const getTranslations = async () => {
      const tlResult = await Api.getTranslations(currentLang, 'support');
      i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
          resources: {
            en: {
              translation: {
                support: tlResult.data,
              },
            },
          },
          lng: process.env.REACT_APP_DEFAULT_LANG,
          fallbackLng: process.env.REACT_APP_DEFAULT_LANG,
          interpolation: {
            escapeValue: false,
          },
        });
    };

    const fetchData = async () => {
      const categoriesResult = await Api.getCategories(currentLang);
      setCategories(categoriesResult.data.categories);

      const faqsResult = await Api.getFAQs(currentLang);
      setFaqs(faqsResult.data.faqs);

      const topSearchesResult = await Api.getMostFrequentedTerms(currentLang, 3);
      setTopSearches(topSearchesResult.data);

      const documents = await Api.getDocuments(currentLang);
      setDocuments(documents.data);

      setIsLoading(false);
    };

    getTranslations(currentLang);
    fetchData();
  }, [setCategories, setFaqs, setTopSearches, setDocuments, currentLang]);

  if (isLoading)
    return (
      <div className="initial-loading-wrap">
        <LoadingIcon />
      </div>
    );

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Faq} />
        <Route path="/results/:term?" component={Results} />
        <Route path="/detail/:id/" component={Detail} />
        <Route component={NoMatchPage} />
      </Switch>
    </div>
  );
};
