import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK, COLOR_GREY_4, COLOR_WHITE } from '../../assets/styles/colors';

export const BodyTextSC = styled.p`
  color: ${COLOR_BLACK};
  font-size: 16px;
  margin-bottom: 0;
  display: inline-block;
  &.white {
    color: ${COLOR_WHITE};
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.bolder {
    font-weight: 500;
  }
  &.grey {
    color: ${COLOR_GREY_4};
  }

  &.smaller {
    font-size: 12px;
  }
`;

const BodyText = (props) => {
  const { children, className } = props;
  const customClass = classNames({ [className]: className !== '' });

  return <BodyTextSC className={customClass}>{children}</BodyTextSC>;
};

BodyText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

BodyText.defaultProps = {
  className: '',
};

export default BodyText;
