import styled from 'styled-components';
import { COLOR_GREY_1, COLOR_BLUE, COLOR_WHITE, COLOR_YELLOW } from '../../assets/styles/colors';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export default styled.header`
  width: 100%;
  position: relative;
  background: ${COLOR_GREY_1};
  height: 100px;
  padding: 0;
  &:before {
    content: '';
    background: ${COLOR_BLUE};
    width: 40%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
  }
  .row {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    .col-logo {
      display: flex;
      align-items: center;
      button {
        padding: 0;
        margin: 0;
        background: none;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        svg {
          min-height: 19px;
          height: 19px;
        }
      }
    }
    .col-buttons {
      button {
        &:last-child {
          margin-left: 30px;
        }
      }
    }
    .col-mobile-menu {
      display: none;
    }
  }
  @media (max-width: ${BP_MOBILE_MAX}px) {
    &.mobile-opened {
      &:before {
        display: none;
      }
    }
    .row {
      .col-buttons {
        display: none;
      }
      .col-mobile-menu {
        display: block;
        z-index: 1;
        button {
          padding: 0px;
          border: none;
          font-size: 14px;
          color: ${COLOR_WHITE};
          border-bottom: 2px solid ${COLOR_YELLOW};
          text-align: center;
          background: transparent;
          &:hover {
            color: ${COLOR_YELLOW};
          }
        }
      }
    }
  }
`;
