import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK, COLOR_WHITE } from '../../assets/styles/colors';

export const HeadingTwoSC = styled.h2`
  color: ${COLOR_BLACK};
  font-size: 29px;
  line-height: 1.35;
  font-weight: 500;
  margin-bottom: 0;
  &.white {
    color: ${COLOR_WHITE};
  }
  &.bold {
    font-weight: bold;
  }
`;

const HeadingTwo = (props) => {
  const { children, className } = props;
  const customClass = classNames({ [className]: className !== '' });

  return <HeadingTwoSC className={customClass}>{children}</HeadingTwoSC>;
};

HeadingTwo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

HeadingTwo.defaultProps = {
  className: '',
};

export default HeadingTwo;
