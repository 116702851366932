import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
/* import { ReactComponent as LogoIcon } from '../../assets/images/svg/logo-b.svg'; */

import FooterSC from './footer.styled';
import Container from '../container';
import { BodyText } from '../typography';
import { StateContext } from '../../context/state-provider';

const Footer = () => {
  const { documents } = useContext(StateContext);
  const { t } = useTranslation();

  /*   const redirectToHomepage = () => {
    window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`;
  }; */

  return (
    <FooterSC>
      <Container>
        <div className="row">
          {/*       <div className="col-logo">
            <button type="button" onClick={() => redirectToHomepage()}>
              <LogoIcon />
            </button>
          </div> */}
          <div className="col-links-wrap">
            <div className="col-copyright">
              <BodyText className="grey">{t('support.footer.copyright')}</BodyText>
            </div>

            <div className="col-gdpr">
              {documents && documents.length > 0 ? (
                <a href={documents.find((item) => item.category === 'gdpr').link}>
                  {' '}
                  {documents.find((item) => item.category === 'gdpr').name}
                </a>
              ) : null}
            </div>
          </div>
          <div className="col-navigation">
            <a href={`${process.env.REACT_APP_HOMEPAGE_URL}/about-project`}>
              {t('support.footer.link_1')}
            </a>
            <a href={`${process.env.REACT_APP_HOMEPAGE_URL}/about-us`}>
              {t('support.footer.link_2')}
            </a>
            <Link to="/">{t('support.footer.link_3')}</Link>
            <a href={`${process.env.REACT_APP_HOMEPAGE_URL}/contact`}>
              {t('support.footer.link_4')}
            </a>
          </div>
        </div>
      </Container>
    </FooterSC>
  );
};

Footer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Footer);
