import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FaqBodySC from './faq-body.styled';
import CategoryBox from '../category-box';
import Container from '../container';
import { StateContext } from '../../context/state-provider';
import HeadingTwo from '../typography/heading-two';

const FaqBody = () => {
  const { t } = useTranslation();
  const { categories } = useContext(StateContext);
  return (
    <FaqBodySC>
      <Container>
        <div className="row">
          <div className="headline-row">
            <HeadingTwo>{t('support.global.faq')}</HeadingTwo>
          </div>
          {categories.map((item) => (
            <CategoryBox key={item.id} category={item} />
          ))}
        </div>
      </Container>
    </FaqBodySC>
  );
};

export default FaqBody;
