import React from 'react';
import PropTypes from 'prop-types';
import ButtonSC from './button.styled';

const Button = ({ children, onClick, disabled, className }) => {
  return (
    <ButtonSC onClick={onClick} disabled={disabled} className={className}>
      {children}
    </ButtonSC>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  onClick: () => {},
  className: '',
  disabled: false,
};

export default Button;
