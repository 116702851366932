import styled from 'styled-components';
import { BP_NOTEBOOK_MAX } from '../../assets/styles/breakpoints';
import { COLOR_GREY_1, COLOR_WHITE, COLOR_YELLOW } from '../../assets/styles/colors';

export default styled.footer`
  max-width: 100%;
  background-color: ${COLOR_GREY_1};
  height: auto;
  padding: 40px 0;
  .row {
    display: flex;
    justify-content: space-between;
    button {
      background: none;
      border: none;
      width: 94px;
      padding: 0px;
    }
    .col-links-wrap {
      display: flex;
      align-items: center;
    }
    .col-copyright {
      p {
        display: flex;
      }
    }
    .col-gdpr {
      margin-left: 30px;
      a {
        color: ${COLOR_WHITE};
        border: none !important;
        &:last-child {
          margin-right: 0px;
          margin-bottom: 30px;
        }
        &:hover {
          color: ${COLOR_YELLOW};
        }
      }
    }
    .col-navigation {
      display: flex;
      justify-content: flex-end;
      a {
        margin-right: 30px;
        color: ${COLOR_WHITE};
        border: none !important;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          color: ${COLOR_YELLOW};
        }
      }
    }
  }
  @media (max-width: ${BP_NOTEBOOK_MAX}px) {
    .row {
      .col-gdpr {
        font-size: 14px;
      }
      .col-navigation {
        a {
          font-size: 14px;
        }
      }
      .col-copyright {
        p {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 1120px) {
    max-width: 100%;
    background-color: ${COLOR_GREY_1};
    height: auto;
    padding: 40px 0;
    .row {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;

      .col-links-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 2;
      }

      .col-logo {
        padding-bottom: 20px;
      }

      .col-gdpr {
        padding-top: 5px;
        order: 1;
        text-align: center;
        font-size: 14px;
      }

      .col-copyright {
        order: 2;
        padding-top: 30px;
        padding-bottom: 20px;
      }
      .col-navigation {
        order: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        a {
          font-size: 14px;
          margin-right: 0px;
          margin-top: 15px;
          color: ${COLOR_WHITE};
          border: none !important;
          &:last-child {
            margin-right: 0px;
            margin-bottom: 30px;
          }
          &:hover {
            color: ${COLOR_YELLOW};
          }
        }
      }
    }
  }
`;
