import React from 'react';
import NoMatchPage from '../components/no-match';
import LeftBanner from '../components/left-banner';

export default () => {
  return (
    <>
      <LeftBanner />
      <NoMatchPage />
    </>
  );
};
