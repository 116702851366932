import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK, COLOR_WHITE } from '../../assets/styles/colors';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export const HeadingThreeSC = styled.h3`
  color: ${COLOR_BLACK};
  font-size: 29px;
  line-height: 1.38;
  font-weight: bold;
  margin-bottom: 0;
  &.white {
    color: ${COLOR_WHITE};
  }
  @media (max-width: ${BP_MOBILE_MAX}px) {
    font-size: 23px;
  }
`;

const HeadingThree = (props) => {
  const { children, className } = props;
  const customClass = classNames({ [className]: className !== '' });

  return <HeadingThreeSC className={customClass}>{children}</HeadingThreeSC>;
};

HeadingThree.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

HeadingThree.defaultProps = {
  className: '',
};

export default HeadingThree;
