import { css } from 'styled-components';
import { COLOR_BLACK, COLOR_YELLOW, COLOR_GREY_1 } from './colors';

export default css`
  @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap');

  @font-face {
    font-family: 'Nexa';
    src: url('/assets/fonts/Nexa-Bold.otf');
    src: local('☺'), url('/assets/fonts/Nexa-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Nexa';
    src: url('/assets/fonts/Nexa-Light.otf');
    src: local('☺'), url('/assets/fonts/Nexa-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Futura';
    src: url('/assets/fonts/Futura-Bold.ttf');
    src: local('☺'), url('/assets/fonts/Futura-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Futura';
    src: url('/assets/fonts/Futura-medium.ttf');
    src: local('☺'), url('/assets/fonts/Futura-medium.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Futura';
    src: url('/assets/fonts/Futura-Light.ttf');
    src: local('☺'), url('/assets/fonts/Futura-Light.ttf') format('ttf');
    font-weight: lighter;
    font-style: normal;
  }

  html {
    font-family: 'Roboto', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Futura', sans-serif;
    font-weight: normal;
  }

  body {
    font-family: 'Poppins', sans-serif;
    color: ${COLOR_GREY_1};
    .initial-loading-wrap {
      width: 100%;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      display: flex;
      svg {
        height: 15px;
      }
    }
    .desk--widget.bottom-right .widget-toggle-row {
      bottom: 15px !important;
    }
  }
  button,
  input {
    font-family: 'Poppins', sans-serif;
    font: unset;
  }
  a,
  button {
    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
  }
  a {
    color: ${COLOR_BLACK};
    border-bottom: 2px solid ${COLOR_YELLOW};
    &:hover {
      color: ${COLOR_YELLOW};
      text-decoration: none;
    }
  }
`;
