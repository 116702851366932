import styled from 'styled-components';
import { rgba } from 'polished';
import {
  COLOR_BLACK,
  COLOR_BLUE,
  COLOR_GREY_1,
  COLOR_GREY_4,
  COLOR_YELLOW,
  COLOR_WHITE,
} from '../../assets/styles/colors';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export const SearchSC = styled.div`
  max-width: 100%;
  position: relative;
  background-color: ${COLOR_GREY_1};
  height: auto;
  padding: 120px 0px 80px 0px;
  box-shadow: 0 1px 0 0 #e3e8ef;
  .glyph-icon {
    position: absolute;
    bottom: 0px;
    right: calc(40% - 280px);
  }
  &:before {
    content: '';
    background: ${COLOR_BLUE};
    width: 40%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
  }

  .row {
    position: relative;
    z-index: 1;
    width: 50%;
    h1 {
      padding-bottom: 20px;
    }
  }

  @media (max-width: ${BP_MOBILE_MAX}px) {
    padding: 50px 0px 50px 0px;

    .glyph-icon {
      display: none;
    }
    .row {
      width: 100%;
      h1 {
        padding-bottom: 20px;
      }
    }
  }
`;

export const SearchFormSC = styled.div`
  background: ${rgba(COLOR_BLACK, 0.9)};
  display: flex;
  margin-bottom: 15px;
  border-radius: 30px;

  @media (max-width: ${BP_MOBILE_MAX}px) {
    display: block;
    border-bottom: none;
    max-height: initial;
    background: none;

    button {
      margin-top: 10px;
      width: 100%;
      max-height: 60px;
    }
  }
`;

export const SearchInputSC = styled.input`
  width: 100%;
  outline: none;
  border-radius: 0;
  height: 60px;
  background: none;
  border: none;
  padding-right: 30px;
  padding-left: 30px;
  color: ${COLOR_WHITE};
  font-size: 16px;

  ::placeholder {
    color: ${COLOR_GREY_4};
  }
  @media (max-width: ${BP_MOBILE_MAX}px) {
    display: block;
    background: ${rgba(COLOR_BLACK, 0.9)};
    border-radius: 30px;
    width: 100%;
    flex: none;
    padding-right: 0px;
    height: 60px;
    font-size: 16px;
  }
`;

export const TopSearchesSC = styled.button`
  color: ${COLOR_WHITE};
  border: none;
  border-bottom: 1px solid ${COLOR_YELLOW};
  display: inline-block;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  text-decoration: none;
  margin-left: 10px;
  :hover {
    color: ${COLOR_YELLOW};
  }
`;
