import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import {
  DetailSC,
  DetailLinkWrapperSC,
  BreadcrumbWrapperSC,
  DetailContentWrapperSC,
} from './detail-body.styled';
import Container from '../container';
import Api from '../../utils/api';
import { StateContext } from '../../context/state-provider';
import { HeadingFour, HeadingThree } from '../typography';
import { ReactComponent as LoadingIcon } from '../../assets/images/svg/spinner.svg';

const DetailBody = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    match: {
      params: { id: answerId },
    },
  } = props;

  const { faqs, categories, currentLang } = useContext(StateContext);
  const [questions, setQuestions] = useState([]);

  // get current faq
  const { categoryId } = faqs.filter((item) => item.id === parseInt(answerId, 10))[0];

  // get current category
  const { name: categoryName } = categories.filter((category) => category.id === categoryId)[0];

  const categoryFaqs = faqs.filter((item) => categoryId === item.categoryId);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = [];

      for (let i = 0; i < categoryFaqs.length; i += 1) {
        const faq = categoryFaqs[i];
        // eslint-disable-next-line no-await-in-loop
        const faqResponseResult = await Api.getFAQResponse(currentLang, faq.id);
        res.push({
          ...faq,
          answer: faqResponseResult.data.answer,
        });
      }
      setQuestions(res);
    };
    fetchData();
  }, [currentLang, faqs]);

  const handleFaqClick = (fId) => {
    props.history.push(`/detail/${fId}`);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  return (
    <DetailSC>
      <Container>
        <div className="row">
          <BreadcrumbWrapperSC>
            <Link to="/">Support</Link>
            <span>{categoryName}</span>
          </BreadcrumbWrapperSC>

          <DetailLinkWrapperSC>
            <HeadingFour>{categoryName}</HeadingFour>
            {categoryFaqs.map((faq) => (
              <button type="button" key={faq.id} onClick={() => handleFaqClick(faq.id)}>
                <ScrollLink to={faq.id} smooth="true" style={{ borderBottom: 'none' }}>
                  {faq.question}
                </ScrollLink>
              </button>
            ))}
          </DetailLinkWrapperSC>

          {isLoading ? (
            <DetailLinkWrapperSC>
              <div className="loading-wrap">
                <LoadingIcon />
              </div>
            </DetailLinkWrapperSC>
          ) : (
            <DetailContentWrapperSC>
              {/* <HeadingOne>{question} CURRENT </HeadingOne>
              {
                <div dangerouslySetInnerHTML={{ __html: content }} />
              } */}

              {questions.map((faq) => (
                <div id={faq.id}>
                  <HeadingThree>{faq.question}</HeadingThree>
                  {
                    // eslint-disable-next-line react/no-danger
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} className="answer" />
                  }
                </div>
              ))}
            </DetailContentWrapperSC>
          )}
        </div>
      </Container>
    </DetailSC>
  );
};

DetailBody.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(DetailBody);
