import React from 'react';
import Header from '../components/header';
import Search from '../components/search';
import ResultsBody from '../components/results-body';
import Footer from '../components/footer';
import ActionBanner from '../components/action-banner';

const Results = () => {
  return (
    <>
      <Header />
      <Search />
      <ResultsBody />
      <ActionBanner />
      <Footer />
    </>
  );
};

export default Results;
