import styled from 'styled-components';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export default styled.div`
  min-height: calc(100vh - 830px);
  .row {
    padding: 0 0 0px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .headline-row {
    padding-top: 60px;
    width: 100%;
  }

  & div:nth-child(2),
  div:nth-child(3) {
    padding-top: 20px;
  }
  @media (max-width: ${BP_MOBILE_MAX}px) {
    flex-direction: column;
    & div:nth-child(3) {
      padding-top: 40px;
    }
  }
`;
