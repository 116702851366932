import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import CategoryBoxSC, { FaqHeadlineSC, FaqLinkSC } from './category-box.styled';
import { StateContext } from '../../context/state-provider';

const CategoryBox = (props) => {
  const {
    category: { id: categoryId, name: categoryName },
  } = props;

  const { faqs } = useContext(StateContext);

  const handleFaqClick = (itemId) => {
    props.history.push(`/detail/${itemId}`);
  };

  return (
    <CategoryBoxSC>
      {' '}
      <FaqHeadlineSC>{categoryName}</FaqHeadlineSC>
      {faqs
        .filter((item) => item.categoryId === categoryId)
        .map((item) => (
          <FaqLinkSC key={item.id} onClick={() => handleFaqClick(item.id)}>
            {item.question}
          </FaqLinkSC>
        ))}
    </CategoryBoxSC>
  );
};

CategoryBox.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(CategoryBox);
