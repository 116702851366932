import styled from 'styled-components';
import { COLOR_GREY_2, COLOR_GREY_4 } from '../../assets/styles/colors';
import { BP_MOBILE_MAX } from '../../assets/styles/breakpoints';

export default styled.div`
  margin: 60px 0;
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .banner-wrap {
      height: 120px;
      width: 817px;
      background: ${COLOR_GREY_2};
      border-radius: 8px;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 40px;
      h4 {
        font-weight: 500;
      }
      p {
        padding-top: 2px;
        color: ${COLOR_GREY_4};
      }
    }
  }

  @media (max-width: ${BP_MOBILE_MAX}px) {
    margin: 30px 0;
    .row {
      .banner-wrap {
        height: auto;
        max-width: 100%;
        background: ${COLOR_GREY_2};
        border-radius: 8px;
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 40px;
      }
    }
  }
`;
