import styled from 'styled-components';
import {
  COLOR_WHITE,
  COLOR_BLUE,
  COLOR_YELLOW,
  COLOR_GREY_1,
  COLOR_YELLOW_1,
} from '../../../assets/styles/colors';

export default styled.button`
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  position: relative;
  padding: 10px 20px 9px 20px;
  &.primary {
    border-radius: 30px;
    color: ${COLOR_GREY_1};
    background: ${COLOR_YELLOW};
    &:hover {
      background: ${COLOR_YELLOW_1};
    }
    &:disabled {
      color: ${COLOR_WHITE};
      background: ${COLOR_BLUE};
    }

    &.not-rounded {
      border-radius: 4px;
    }
  }
  &.secondary {
    font-size: 14px;
    color: ${COLOR_WHITE};
    border-bottom: 2px solid ${COLOR_YELLOW};
    text-align: center;
    background: transparent;
    &:hover {
      color: ${COLOR_YELLOW};
    }
    &.medium {
      padding: 9px 20px 8px 20px;
    }
    &.dark {
      color: ${COLOR_GREY_1};
      &:hover {
        color: ${COLOR_YELLOW};
      }
    }
    &.big {
      padding: 0px 0px 0px 0px;
      border-radius: 0px;
    }
  }
  &:disabled {
    opacity: 0.5;
  }
  &.medium {
    padding: 10px 20px 9px 20px;
  }

  &.bigger {
    padding: 13px 40px 12px 40px;
    font-size: 15px;
  }

  &.big {
    padding: 20px 50px 19px 50px;
  }

  &:focus,
  &:active {
    outline: none;
  }
`;
