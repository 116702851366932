import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK, COLOR_WHITE } from '../../assets/styles/colors';

export const BiggerTextSC = styled.span`
  color: ${COLOR_BLACK};
  font-size: 18px;
  margin-bottom: 0;
  display: inline-block;
  &.white {
    color: ${COLOR_WHITE};
  }
`;

const BiggerText = (props) => {
  const { children, className } = props;
  const customClass = classNames({ [className]: className !== '' });

  return <BiggerTextSC className={customClass}>{children}</BiggerTextSC>;
};

BiggerText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

BiggerText.defaultProps = {
  className: '',
};

export default BiggerText;
